var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-file-find", title: "Consultas" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              !_vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "success darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(1)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-file-account-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3296555324
                      )
                    },
                    [_c("span", [_vm._v("Relatório Disponibilidade Resumido")])]
                  )
                : _vm._e(),
              !_vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "indigo darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(10)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-file-document")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        653724494
                      )
                    },
                    [
                      _c("span", [
                        _vm._v("Relatório Disponibilidade Detalhado")
                      ])
                    ]
                  )
                : _vm._e(),
              !_vm.isAnima()
                ? _c("s-icon-report-download", {
                    attrs: {
                      "show-message": "",
                      "tool-tip": "Horários em Linhas",
                      report: "12"
                    }
                  })
                : _vm._e(),
              _c("s-icon-report-download", {
                attrs: {
                  "show-message": "",
                  "tool-tip": "Relatório de Mensagems",
                  color: "primary",
                  report: "108"
                }
              }),
              !_vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "amber darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(45)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-microsoft-excel")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3231571935
                      )
                    },
                    [_c("span", [_vm._v(" Relatório de Horários Agrupados ")])]
                  )
                : _vm._e(),
              !_vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "teal darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(46)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-microsoft-excel")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1135786745
                      )
                    },
                    [_c("span", [_vm._v(" Cronograma de Aulas ")])]
                  )
                : _vm._e(),
              !_vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "purple darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(3)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-microsoft-excel")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1398133786
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(" Relatório de Turmas e Disciplinas ")
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "teal darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showFilterPrints = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-printer-check")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Imprimir Horários")])]
              ),
              _c("v-divider", {
                staticClass: "mr-2",
                attrs: { vertical: "", inset: "" }
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    disabled: _vm.selectedType == "ambience",
                                    icon: "",
                                    small: ""
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "green" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      _vm.showGrids = !_vm.showGrids
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.showGrids
                                          ? "mdi-eye-off-outline"
                                          : "mdi-eye-check-outline"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showGrids
                            ? "Ocultar Disciplinas"
                            : "Ver disciplinas"
                        ) +
                        " "
                    )
                  ])
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("sys-print-time-table", {
        attrs: { show: _vm.showFilterPrints },
        on: {
          "update:show": function($event) {
            _vm.showFilterPrints = $event
          }
        }
      }),
      _c("emc-message-chat", {
        attrs: {
          show: _vm.dialogChatGroup,
          model: _vm.group,
          type: "group",
          title: "Registro de Anotações da Turma"
        },
        on: {
          "update:show": function($event) {
            _vm.dialogChatGroup = $event
          }
        }
      }),
      _c("emc-message-chat", {
        attrs: {
          show: _vm.dialogChatGrid,
          model: _vm.selectedGrid,
          type: "grid",
          title: "Registro de Anotações da Disciplina"
        },
        on: {
          "update:show": function($event) {
            _vm.dialogChatGrid = $event
          }
        }
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: "success", text: "", centered: "", timeout: "2000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_c("div", { staticClass: "text-center" }, [_vm._v(" Link copiado ")])]
      ),
      _c(
        "div",
        { staticClass: "text-left" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "12", md: "3" } },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { focusable: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        { attrs: { height: "200px" } },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: { "disable-icon-rotate": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v(" mdi-account-group-outline ")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [_vm._v(" Turmas ")]
                          ),
                          _c("v-expansion-panel-content", [
                            _c(
                              "div",
                              [
                                _c(
                                  "emc-util-scroll",
                                  { attrs: { maxHeight: "300px" } },
                                  [
                                    _c("sys-group-tree-view", {
                                      attrs: { "show-category": "" },
                                      on: {
                                        select: function($event) {
                                          return _vm.selectItem("group", $event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: { "disable-icon-rotate": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v(" mdi-home ")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [_vm._v(" Ambientes ")]
                          ),
                          _c("v-expansion-panel-content", [
                            _c(
                              "div",
                              [
                                _c("v-text-field", {
                                  staticClass: "pa-1 ",
                                  attrs: {
                                    "append-icon": "mdi-magnify",
                                    label: "Pesquisar",
                                    "single-line": "",
                                    "hide-details": "",
                                    clearable: "",
                                    dense: ""
                                  },
                                  model: {
                                    value: _vm.searchAmbience,
                                    callback: function($$v) {
                                      _vm.searchAmbience = $$v
                                    },
                                    expression: "searchAmbience"
                                  }
                                }),
                                _c(
                                  "emc-util-scroll",
                                  { attrs: { maxHeight: "300px" } },
                                  [
                                    _c("sys-ambience-tree-view", {
                                      attrs: { search: _vm.searchAmbience },
                                      on: {
                                        select: function($event) {
                                          return _vm.selectItem(
                                            "ambience",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: { "disable-icon-rotate": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v(" mdi-school-outline ")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [_vm._v(" Professores ")]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("v-data-table", {
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.teachers,
                                  search: _vm.searchTeacher,
                                  loading: _vm.loadingTeacher,
                                  "fixed-header": "",
                                  height: "200px",
                                  "hide-default-footer": ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "top",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-card-title",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "append-icon": "mdi-magnify",
                                                label: "Pesquisar",
                                                "single-line": "",
                                                "hide-details": "",
                                                autofocus: ""
                                              },
                                              on: {
                                                "click:append": function(
                                                  $event
                                                ) {
                                                  return _vm.getTeachers()
                                                },
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.getTeachers()
                                                }
                                              },
                                              model: {
                                                value: _vm.searchTeacher,
                                                callback: function($$v) {
                                                  _vm.searchTeacher = $$v
                                                },
                                                expression: "searchTeacher"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "item.actions",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color:
                                                                "blue darken-4",
                                                              right: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectItem(
                                                                  "teacher",
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " mdi-calendar-clock "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [_c("span", [_vm._v("Ver horário")])]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color:
                                                                "orange darken-4",
                                                              right: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openExtract(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " mdi-open-in-new "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Abrir extrato")
                                            ])
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    xs: "12",
                    md: _vm.selectedType != "ambience" && _vm.showGrids ? 7 : 9
                  }
                },
                [
                  _vm.teacher && _vm.teacher.hasTimeTables
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("SysTeacherOtherActivityTimeTable", {
                                attrs: { teacher: _vm.teacher }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-simple-table", {
                            staticClass: "time-table",
                            attrs: { "fixed-header": "", height: "500px" },
                            scopedSlots: _vm._u([
                              {
                                key: "top",
                                fn: function() {
                                  return [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        indeterminate: "",
                                        color: _vm.loading ? "cyan" : ""
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            staticStyle: { width: "60px" }
                                          },
                                          [
                                            _vm.group
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("v-row", [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(_vm.group.name)
                                                        )
                                                      ])
                                                    ]),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {},
                                                                            "span",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "emc-message-box",
                                                                            {
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer"
                                                                              },
                                                                              attrs: {
                                                                                model:
                                                                                  _vm.group
                                                                              },
                                                                              on: {
                                                                                "update:model": function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.group = $event
                                                                                },
                                                                                onOpen: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.dialogChatGroup = true
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              100426606
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Registro de Anotações da turma"
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm.group &&
                                                        _vm.group.validated_time
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticStyle: {
                                                                  cursor:
                                                                    "default"
                                                                },
                                                                attrs: {
                                                                  icon: "",
                                                                  "x-small": "",
                                                                  color:
                                                                    "success"
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-check-bold"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Segunda")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Terça")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Quarta")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Quinta")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Sexta")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.showSaturday,
                                                expression: "showSaturday"
                                              }
                                            ],
                                            staticClass: "text-center"
                                          },
                                          [_vm._v("Sábado")]
                                        )
                                      ])
                                    ]),
                                    _vm.renderComponent
                                      ? _c(
                                          "tbody",
                                          _vm._l(_vm.hours, function(hour) {
                                            return _c(
                                              "tr",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.showHour(hour),
                                                    expression: "showHour(hour)"
                                                  }
                                                ],
                                                key: hour.id
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(hour.start) +
                                                          " "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(hour.end) +
                                                          " "
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.days_number,
                                                  function(d) {
                                                    return _c(
                                                      "td",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              d != 7 ||
                                                              _vm.showSaturday,
                                                            expression:
                                                              "d != 7 || showSaturday"
                                                          }
                                                        ],
                                                        key: d,
                                                        class: _vm.getClassCell(
                                                          d,
                                                          hour
                                                        )
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "ma-0 pa-0"
                                                          },
                                                          _vm._l(
                                                            _vm.getGrids(
                                                              d,
                                                              hour
                                                            ),
                                                            function(grid, i) {
                                                              return _c(
                                                                "v-col",
                                                                {
                                                                  key: i,
                                                                  staticClass:
                                                                    "ma-0 pa-0"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "ma-1",
                                                                      attrs: {
                                                                        transition:
                                                                          "fab-transition",
                                                                        color:
                                                                          grid.color,
                                                                        dark:
                                                                          "",
                                                                        width:
                                                                          "160px",
                                                                        height:
                                                                          "60px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "span",
                                                                                      _vm._g(
                                                                                        {},
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-card-title",
                                                                                          {
                                                                                            staticClass:
                                                                                              "pa-0",
                                                                                            staticStyle: {
                                                                                              position:
                                                                                                "absolute",
                                                                                              top:
                                                                                                "2px",
                                                                                              left:
                                                                                                "5px",
                                                                                              "font-size":
                                                                                                "1em"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.getNameDiscipline(
                                                                                                  grid
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "v-card-subtitle",
                                                                                          {
                                                                                            staticClass:
                                                                                              "pa-0",
                                                                                            staticStyle: {
                                                                                              position:
                                                                                                "absolute",
                                                                                              top:
                                                                                                "44px",
                                                                                              left:
                                                                                                "6px",
                                                                                              "font-size":
                                                                                                "1em"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm.selectedType ==
                                                                                            "group"
                                                                                              ? _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    grid.teacher
                                                                                                      ? _c(
                                                                                                          "span",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                _vm.getNameTeacher(
                                                                                                                  grid
                                                                                                                )
                                                                                                              )
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      : _vm._e()
                                                                                                  ]
                                                                                                )
                                                                                              : _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " " +
                                                                                                        _vm._s(
                                                                                                          grid
                                                                                                            .group
                                                                                                            .name
                                                                                                        ) +
                                                                                                        " "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.getNameDisciplineToolTip(
                                                                                      grid
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                              grid.specificity
                                                                                ? _c(
                                                                                    "small",
                                                                                    [
                                                                                      _c(
                                                                                        "br"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        "[" +
                                                                                          _vm._s(
                                                                                            grid
                                                                                              .specificity
                                                                                              .name
                                                                                          ) +
                                                                                          "]"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              grid.teacher
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _c(
                                                                                        "br"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          grid
                                                                                            .teacher
                                                                                            .name
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      grid
                                                                                        .group
                                                                                        .name
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              grid.subGroup
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        " [" +
                                                                                          _vm._s(
                                                                                            grid
                                                                                              .subGroup
                                                                                              .name
                                                                                          ) +
                                                                                          "]"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.renderComponent &&
                                                                              grid.dates &&
                                                                              grid
                                                                                .dates
                                                                                .length >
                                                                                0
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _c(
                                                                                        "ul",
                                                                                        {
                                                                                          attrs: {
                                                                                            type:
                                                                                              "none"
                                                                                          }
                                                                                        },
                                                                                        _vm._l(
                                                                                          grid.dates,
                                                                                          function(
                                                                                            date,
                                                                                            i
                                                                                          ) {
                                                                                            return _c(
                                                                                              "li",
                                                                                              {
                                                                                                key: i
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        date.initials
                                                                                                      ) +
                                                                                                        " : " +
                                                                                                        _vm._s(
                                                                                                          _vm._f(
                                                                                                            "date"
                                                                                                          )(
                                                                                                            date.date
                                                                                                          )
                                                                                                        )
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        0
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm.renderComponent
                                                                        ? _c(
                                                                            "v-card-actions",
                                                                            [
                                                                              grid &&
                                                                              grid.join
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    {
                                                                                                      staticStyle: {
                                                                                                        position:
                                                                                                          "absolute",
                                                                                                        bottom:
                                                                                                          "4px",
                                                                                                        right:
                                                                                                          "-5px",
                                                                                                        cursor:
                                                                                                          "help"
                                                                                                      },
                                                                                                      attrs: {
                                                                                                        "x-small":
                                                                                                          "",
                                                                                                        left:
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " mdi-call-split "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      grid
                                                                                        .join
                                                                                        .grids
                                                                                        ? _c(
                                                                                            "span",
                                                                                            [
                                                                                              [
                                                                                                _c(
                                                                                                  "ul",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      type:
                                                                                                        "none"
                                                                                                    }
                                                                                                  },
                                                                                                  _vm._l(
                                                                                                    grid
                                                                                                      .join
                                                                                                      .grids,
                                                                                                    function(
                                                                                                      grid
                                                                                                    ) {
                                                                                                      return _c(
                                                                                                        "li",
                                                                                                        {
                                                                                                          key:
                                                                                                            grid.id
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                grid
                                                                                                                  .group
                                                                                                                  .campus
                                                                                                                  .name +
                                                                                                                  " | " +
                                                                                                                  grid
                                                                                                                    .group
                                                                                                                    .name +
                                                                                                                  " | " +
                                                                                                                  grid
                                                                                                                    .work_load_type
                                                                                                                    .initials +
                                                                                                                  " | " +
                                                                                                                  grid.discipline_name
                                                                                                              ) +
                                                                                                              " "
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    }
                                                                                                  ),
                                                                                                  0
                                                                                                )
                                                                                              ]
                                                                                            ],
                                                                                            2
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " Carregando informações..."
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.selectedType !=
                                                                                "ambience" &&
                                                                              grid &&
                                                                              (grid.ambience ||
                                                                                grid.link)
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    {
                                                                                                      staticStyle: {
                                                                                                        position:
                                                                                                          "absolute",
                                                                                                        bottom:
                                                                                                          "4px",
                                                                                                        left:
                                                                                                          "5px",
                                                                                                        cursor:
                                                                                                          "help"
                                                                                                      },
                                                                                                      attrs: {
                                                                                                        "x-small":
                                                                                                          "",
                                                                                                        left:
                                                                                                          ""
                                                                                                      },
                                                                                                      on: {
                                                                                                        click: function(
                                                                                                          $event
                                                                                                        ) {
                                                                                                          $event.preventDefault()
                                                                                                          $event.stopPropagation()
                                                                                                          return _vm.copyToClipBoard(
                                                                                                            grid.link
                                                                                                          )
                                                                                                        }
                                                                                                      }
                                                                                                    },
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " " +
                                                                                                        _vm._s(
                                                                                                          grid
                                                                                                            .ambience
                                                                                                            .type
                                                                                                            .is_virtual
                                                                                                            ? "mdi-monitor-dashboard"
                                                                                                            : "mdi-home"
                                                                                                        ) +
                                                                                                        " "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.getAmbienceName(
                                                                                              grid,
                                                                                              d,
                                                                                              hour
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              grid.category &&
                                                                              !grid
                                                                                .category
                                                                                .default
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-chip",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "mb-1",
                                                                                                        staticStyle: {
                                                                                                          position:
                                                                                                            "absolute",
                                                                                                          bottom:
                                                                                                            "0px",
                                                                                                          right:
                                                                                                            "30px",
                                                                                                          cursor:
                                                                                                            "help"
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "white",
                                                                                                          label:
                                                                                                            "",
                                                                                                          "x-small":
                                                                                                            "",
                                                                                                          outlined:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      "v-chip",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " " +
                                                                                                        _vm._s(
                                                                                                          grid
                                                                                                            .category
                                                                                                            .initials
                                                                                                        ) +
                                                                                                        " "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              grid
                                                                                                .category
                                                                                                .name
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              grid &&
                                                                              grid.work_time_date
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    {
                                                                                                      style: grid.join
                                                                                                        ? "position: absolute; bottom: 4px; right: 10px; cursor: help"
                                                                                                        : "position: absolute; bottom: 4px; right: -5px; cursor: help",
                                                                                                      attrs: {
                                                                                                        "x-small":
                                                                                                          "",
                                                                                                        left:
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " event "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              grid
                                                                                                .work_time_date
                                                                                                .name
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e()
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showGrids && _vm.selectedType != "ambience"
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", xs: "12", md: "2" } },
                    [
                      _c("span", [
                        _vm.showGrids
                          ? _c("strong", [_vm._v("Disciplinas")])
                          : _c("strong", [_vm._v("Pendências")])
                      ]),
                      _c(
                        "emc-util-scroll",
                        { attrs: { maxHeight: "500px" } },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  model: {
                                    value: _vm.modelGrid,
                                    callback: function($$v) {
                                      _vm.modelGrid = $$v
                                    },
                                    expression: "modelGrid"
                                  }
                                },
                                _vm._l(
                                  _vm.showGrids
                                    ? _vm.grids
                                    : _vm.getPendencieGrids(),
                                  function(item, i) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: i,
                                        on: {
                                          click: function($event) {
                                            return _vm.loadTeacherData(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "strong",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      class: !item.has_time_table
                                                                        ? "grey--text"
                                                                        : item.pendencies >
                                                                          0
                                                                        ? "red--text"
                                                                        : "blue--text"
                                                                    },
                                                                    "strong",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c("small", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .discipline
                                                                          .name
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getNameDisciplineToolTip(
                                                              item
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      item.teacher
                                                        ? _c("span", [
                                                            _c("br"),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.teacher
                                                                    .name
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ])
                                                  ]
                                                ),
                                                _c("br"),
                                                _c("span", [
                                                  item.pendencies > 0
                                                    ? _c("small", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.work_load_type
                                                          ) +
                                                            " = " +
                                                            _vm._s(
                                                              item.pendencies
                                                            ) +
                                                            " / " +
                                                            _vm._s(
                                                              item.work_load_time
                                                                ? item.work_load_time
                                                                : item.work_load
                                                            )
                                                        )
                                                      ])
                                                    : _c("small", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.work_load_type
                                                          ) +
                                                            " = " +
                                                            _vm._s(
                                                              item.work_load_time
                                                                ? item.work_load_time
                                                                : item.work_load
                                                            )
                                                        )
                                                      ]),
                                                  item.group_number != 0
                                                    ? _c("small", [
                                                        _vm._v(
                                                          " | D" +
                                                            _vm._s(
                                                              item.group_number
                                                            )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  item.teacher_number != 0
                                                    ? _c("small", [
                                                        _vm._v(
                                                          " | P" +
                                                            _vm._s(
                                                              item.teacher_number
                                                            )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]),
                                                _vm.selectedType == "group" ||
                                                _vm.selectedType == "ambience"
                                                  ? _c("span", [
                                                      item.teacher
                                                        ? _c("span", [
                                                            _c("br"),
                                                            _c("small", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.teacher
                                                                    .name
                                                                )
                                                              )
                                                            ])
                                                          ])
                                                        : _vm._e()
                                                    ])
                                                  : _vm._e(),
                                                _vm.selectedType ==
                                                  "ambience" ||
                                                _vm.selectedType == "teacher"
                                                  ? _c("span", [
                                                      _c("br"),
                                                      _c("small", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.group.name
                                                          )
                                                        )
                                                      ])
                                                    ])
                                                  : _vm._e(),
                                                _c("br"),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      cursor: "help"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.openChatGrid(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    item.has_message
                                                      ? _c(
                                                          "v-badge",
                                                          {
                                                            staticClass: "ma-0",
                                                            attrs: {
                                                              bordered: "",
                                                              top: "",
                                                              color: "red",
                                                              overlap: "",
                                                              dot: ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "blue darken-2",
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-email "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "grey",
                                                              small: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-email "
                                                            )
                                                          ]
                                                        )
                                                  ],
                                                  1
                                                ),
                                                item.work_time_date
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " event "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item
                                                                  .work_time_date
                                                                  .name
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5 grey lighten-2" },
                    [
                      _vm._v(" Extrato do Professor "),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-1",
                                          attrs: { icon: "" }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "red lighten-1" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.extract.createPDF()
                                            }
                                          }
                                        },
                                        [_vm._v("mdi-file-pdf-box")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Imprimir Extrato")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-1",
                                          attrs: { icon: "", small: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialog = false
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red lighten-2" } },
                                        [_vm._v("mdi-close")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Fechar")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("SysTeacherExtract", {
                        ref: "extract",
                        attrs: { teacher: _vm.model }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }